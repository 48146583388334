
#login_section {
	width:100%;
	max-width: 450px;
	margin:auto;
}

.login {
	padding-top: 2%;
}

#register_section {
	width:100%;
	max-width: 450px;
	margin:auto;
}

.register {
	padding-top: 2%;
}


#profile_section {
	width:100%;
	max-width: 500px;
	margin:auto;
}

label {
	text-align: left;
}

.profile {
	padding-top: 1%;
}
#liked_movie_section {
    padding-left: 5%;
}
#need_login {
    margin-left: 25%;
}

.random_display {
    margin-left: 10%;
}

.v_scroll {
    overflow-y: scroll;
    height: 400px;
}

.whitespace {
    white-space: pre;
}
